import React from "react";

export default ({ color, height }) => (
	<svg width="62" height="56" viewBox="0 0 62 56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M31.9971 24.5948C39.4159 24.525 46.8348 24.8739 54.183 25.4322C56.0907 25.5717 57.0092 26.5487 57.0092 28.5026C57.0092 30.4566 56.0907 31.3638 54.183 31.5033C42.8781 32.3407 31.6438 32.6199 20.339 32.0616C16.7355 31.922 13.2027 31.7127 9.59932 31.4335C7.69162 31.294 6.84375 30.317 6.84375 28.3631C6.84375 26.4789 7.76227 25.5717 9.59932 25.4322C17.0888 24.8739 24.5076 24.525 31.9971 24.5948Z" fill={color}  />
		<path d="M34.3291 41.2761C32.9866 41.2761 31.6442 41.2761 30.3017 41.2761C29.3832 41.2761 24.0134 41.0668 23.2362 40.997C19.3501 40.7179 15.4641 40.2294 11.578 39.5315C10.2355 39.3222 9.31703 38.5546 8.75178 37.2985C7.83326 34.9956 9.17571 33.2511 11.578 33.4604C21.5404 34.4374 31.5029 34.5769 41.4653 34.228C44.8568 34.0885 48.2482 33.8093 51.6397 33.5302C53.9007 33.3208 55.0312 33.8093 55.3138 35.3446C55.6671 37.2287 54.5366 39.1128 52.5582 39.4618C50.0853 39.9502 47.6123 40.2992 45.1394 40.5783C41.4653 40.997 37.9325 41.2761 34.3291 41.2761Z" fill={color}  />
		<path d="M27.614 22.5046C22.4562 22.6442 17.2983 22.8535 12.1405 23.342C10.0914 23.5514 8.96096 23.1327 8.60768 21.737C8.18375 19.9924 9.03161 18.1781 10.798 17.6198C11.9285 17.2011 13.1296 17.0615 14.3308 16.922C26.6248 15.1774 38.9189 15.1774 51.1423 17.2011C54.0392 17.6896 55.3816 18.8759 55.311 21.109C55.2403 22.6442 54.3218 23.6212 52.6967 23.5514C50.789 23.4118 48.8813 23.2722 46.9736 23.0629C46.691 22.9931 32.0653 22.3651 27.614 22.5046Z" fill={color}  />
		<path d="M48.4576 15.039C43.865 14.4808 38.9897 14.0621 34.1145 13.9923C28.2501 13.9225 22.3857 14.2016 16.5919 14.8994C14.0483 15.1786 13.3418 14.8297 13.1298 13.5038C12.8472 11.829 14.2603 10.0146 16.168 9.66571C19.0649 9.10744 21.9617 8.82831 24.9293 8.68874C32.4894 8.27004 40.1202 8.40961 47.6804 9.66571C49.3054 9.94484 50.5772 11.1312 50.8599 12.5966C51.0718 14.1318 50.2946 15.1088 48.4576 15.039Z" fill={color}  />
		<path d="M31.1463 42.8812C36.7987 43.0208 42.5218 42.6718 48.1742 41.9042C49.0928 41.7647 50.0113 41.7647 50.5765 42.6021C51.2124 43.4395 51.0005 44.3466 50.5765 45.184C50.0113 46.3006 49.0928 47.0682 47.821 47.2775C40.1902 48.4639 32.5594 48.6732 24.8579 48.1847C22.0317 47.9754 19.2055 47.766 16.3793 47.2775C14.5422 46.9984 13.1998 45.6027 13.1291 43.9977C13.0584 42.6021 13.9063 41.6949 15.3194 41.8344C16.5912 41.974 17.7924 42.1136 19.0642 42.2531C20.1947 42.3927 29.2386 42.8114 31.1463 42.8812Z" fill={color}  />
		<path d="M31.3618 2.61673C35.1772 2.54694 38.4273 2.47716 41.6068 3.31456C42.808 3.59369 43.9385 4.15196 44.9276 4.98936C45.4929 5.47784 45.7049 6.03611 45.4929 6.66416C45.2103 7.36199 44.645 7.57134 43.9385 7.50155C42.384 7.36199 40.759 7.22242 39.2045 7.08285C32.9869 6.66416 26.7692 6.87351 20.6221 7.57134C19.7036 7.64112 18.7851 7.78069 18.4318 6.66416C18.0785 5.61741 18.8558 5.05914 19.5623 4.57066C21.3287 3.38434 23.3071 2.96564 25.3561 2.82608C27.5464 2.61673 29.7367 2.47716 31.3618 2.61673Z" fill={color}  />
		<path d="M31.9935 54.1845C28.814 54.2542 25.5638 54.4636 22.3843 53.6262C21.1832 53.2773 19.982 52.8586 19.0635 52.0212C18.4983 51.5327 18.145 50.9744 18.4276 50.2068C18.7809 49.3694 19.4874 49.2298 20.2646 49.3694C24.7866 49.9277 29.3792 50.137 33.9012 50.0672C37.0807 49.9975 40.1895 49.7881 43.369 49.4392C44.2169 49.3694 44.9941 49.3694 45.418 50.2068C45.8419 51.114 45.2767 51.6723 44.6408 52.2305C42.7331 53.696 40.5428 54.1147 38.2111 54.2542C36.1621 54.2542 34.0425 54.1845 31.9935 54.1845Z" fill={color}  />
		<path d="M54.6421 0.83619C52.3559 1.0507 50.6764 3.07797 50.8909 5.36423C51.1054 7.65049 53.1327 9.32997 55.419 9.11546C57.7052 8.90095 59.3847 6.87367 59.1702 4.58742C58.9557 2.30116 56.9284 0.621679 54.6421 0.83619Z" fill="#333333" />
		<path d="M51.5989 2.99916C49.7283 3.17467 48.3542 4.83335 48.5297 6.70392C48.7052 8.5745 50.3639 9.94862 52.2345 9.77311C54.1051 9.5976 55.4792 7.93892 55.3037 6.06835C55.1282 4.19778 53.4695 2.82365 51.5989 2.99916Z" fill="#F76E9C" />
		<path d="M58.88 45.9745C57.6325 43.6326 54.7899 42.7097 52.5308 43.9131C50.2717 45.1165 49.4517 47.9905 50.6992 50.3324C51.9467 52.6743 54.7894 53.5972 57.0484 52.3938C59.3075 51.1904 60.1275 48.3164 58.88 45.9745Z" fill="#333333" />
		<path d="M55.2852 43.6579C54.2645 41.7418 51.9387 40.9867 50.0904 41.9713C48.2421 42.956 47.5711 45.3074 48.5918 47.2235C49.6125 49.1396 51.9383 49.8947 53.7867 48.9101C55.635 47.9255 56.3059 45.574 55.2852 43.6579Z" fill="#FFB000" />
		<path d="M4.40981 15.3037C6.08278 16.4917 8.40198 16.0985 9.5899 14.4255C10.7778 12.7525 10.3846 10.4333 8.71166 9.24541C7.0387 8.05748 4.71949 8.45069 3.53157 10.1236C2.34365 11.7966 2.73685 14.1158 4.40981 15.3037Z" fill="#333333" />
		<path d="M7.73154 15.6139C9.10033 16.5858 10.9979 16.2641 11.9698 14.8953C12.9417 13.5265 12.62 11.629 11.2512 10.6571C9.88245 9.68513 7.98492 10.0068 7.01298 11.3756C6.04104 12.7444 6.36276 14.6419 7.73154 15.6139Z" fill="#73A77B" />
	</svg>

);
